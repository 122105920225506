import { Button, Switch, Title } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { useAdminUpdateSettingsMutation } from "../../graphql/graphql";

type PaymentProviderSettingsProps = {
  paymentProvider: {
    type: string;
    name: string;
    meta: Record<string, any>;
  };
};
export const PaymentProviderSettings = ({
  paymentProvider,
}: PaymentProviderSettingsProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useAdminUpdateSettingsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["AdminSettings"],
      });
    },
  });
  const togglePaymentProvider = () => {
    mutate({
      input: {
        setting: {
          type: "paymentProvider",
          meta: {},
        },
      },
    });
  };
  return (
    <div>
      <Title order={2}>Ustawienia płatności dla {paymentProvider.name}</Title>
      <div style={{ padding: "0 8px" }}>
        <Switch
          label={paymentProvider.meta.test ? "Test" : "Live"}
          checked={!paymentProvider.meta.test}
          onChange={togglePaymentProvider}
        />
      </div>
    </div>
  );
};
