import { Center, Loader, Title } from "@mantine/core";
import * as React from "react";

import { useAdminSettingsQuery } from "../../graphql/graphql";
import { PaymentProviderSettings } from "../views/PaymentProviderSettings";

const CenterLoader = (
  <Center style={{ height: "100%" }}>
    <Loader />
  </Center>
);

export const SettingsIndexPage = () => {
  const { data, isLoading } = useAdminSettingsQuery();

  const paymentProvider = data?.adminSettings?.find(
    (setting) => setting.type === "paymentProvider",
  ) || { meta: {} };
  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Title order={1}>Ustawienia</Title>
        {isLoading ? (
          CenterLoader
        ) : (
          <PaymentProviderSettings paymentProvider={paymentProvider} />
        )}
      </div>
    </>
  );
};
